<template>
  <!-- 关于我们 - 体验中心页面 -->
  <div class="experience-center-page">
    <!-- 轮播模块 -->
    <div class="banner banner-part">
      <img :src="$IMG_BASE_URL+pageData.banner" alt="" @load="bannerLoadOver = true">
      <div class="banner-text banner-type-1" :class="bannerLoadOver? 'animate': ''">
        <p class="zh"><span>体验中心</span></p>
        <p class="en"><span>EXPERIENCE CENTER</span></p>
      </div>
    </div>
    <!-- 中心介绍模块 -->
    <div class="desc-part">
      <div class="w">
        <h3 class="part-title"><span>中心介绍</span></h3>
        <div class="part-content t-center">
          <p class="fc-main fs-24 mb-30 fw-bold">{{ pageData.title }}</p>
          <p class="fs-16 fc-text line-break">{{ pageData.content1 }}</p>
        </div>
      </div>
    </div>
    <div class="future-part">
      <div class="w">
        <img class="title-img" :src="$IMG_BASE_URL+pageData.title2" alt="">
        <p class="text fc-main fs-16 line-break">{{ pageData.content2 }}</p>
      </div>
    </div>
    <!-- 图片展览部分 -->
    <div class="pic-part">
      <div class="w">
        <div class="part-content">
          <div v-for="item in showImgs" :key="item"><img :src="item" alt=""></div>
      </div>
      </div>
    </div>

    <!-- 新模式部分 -->
    <div class="model-part">
      <div class="w">
        <img class="title-img" :src="$IMG_BASE_URL+pageData.title3" alt=""/>
        <div class="part-content">
         <div class="text fc-white fs-16 line-break">{{ pageData.content3 }}</div>
         <div class="video-wrap">
           <div class="video-box">
            <video ref="videoRef" :src="$IMG_BASE_URL+pageData.video" :controls="isPlay"></video>
            <div class="play-btn" v-show="!isPlay" @click.stop="handlePlay"></div>
           </div>
          <div class="video-desc">
            <p class="fc-white fs-16 mb-30 line-break fw-bold">{{ pageData.videoText }}</p>
            <img src="@/assets/experienceCenter/arrow.png" alt="">
          </div>
         </div>
         <div class="code-wrap flex-c-c">
          <img style="width:172px;height:172px;" :src="$IMG_BASE_URL+pageData.qrCode" alt="">
          <div class="code-desc">
            <img src="@/assets/experienceCenter/code-title.png" alt="">
            <hr>
            <p class="fs-16 fc-white">{{ pageData.qrCodeText }}</p>
          </div>
         </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  data() {
    return {
      channelId: 38, // 栏目id，用来获取栏目下的内容

      bannerLoadOver: false, // banner图片是否加载完成
      isPlay: false,
      pageData: {},
      showImgs: [], // 展示图片
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    // 掉接口 - 获取体验中心栏目下的文章列表（默认只取已审核文章第一条）
    async getPageData() {
      const { message, contents } = await api.getContentsList({ channelId:this.channelId })
      const index = contents.findIndex(item => item.state === '已审核')
      this.pageData = contents && contents[index] || {}

      this.formatShowImgs(this.pageData.showImgs)
    },  
    // 从富文本中提取img信息
    formatShowImgs(elStr) {
      if(!elStr) return
      const srcArr = elStr.match(/src=["][\w/]*(.png|.jpg|.jpeg)/g)
      srcArr.map(item => {
        const src = item.split('="')[1]
        src ? this.showImgs.push(this.$IMG_BASE_URL + src) : ''
      })
    },
    handlePlay() {
      this.$refs.videoRef.play()
      this.isPlay = true
    }
  },
}
</script>

<style lang="less" scoped>
  @import url('~@/styles/m.less');
  @import url('~@/styles/animate.less');

  .experience-center-page{
    .desc-part{
      padding: 70px 0 80px;
      background-color: #fff;
    }
    .future-part{
      height: 752px;
      background: url('~@/assets/experienceCenter/car-bg.png') no-repeat center center/100%;
    }
    .pic-part{
      height: 700px;
      padding: 54px 0 62px;
      background: #fff;
    }
    .model-part{
      height: 1300px;
      padding: 80px 0 75px;
      background: url('~@/assets/experienceCenter/model-bg.png') no-repeat center center/100% 100%;
    }
  }

  /* banner文字动画 */ 
  .banner-type-1.animate{
    .fade-scale-in(.9, 1, -50%,-50%, .5s, ease-out, forwards)
  }

  .future-part{
    .title-img{
      margin: 158px 0 36px;
    }
    .text{
      width: 80%;
    }
  }

  .pic-part{
    .part-content{
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      &>div{ overflow: hidden; height: 290px; }
      div:nth-child(1) { width: 65%; margin-right: 10px; margin-bottom: 10px;}
      div:nth-child(2) { width: 33%; margin-bottom: 10px;}
      div:nth-child(3) { width: 49%; margin-right: 10px;}
      div:nth-child(4) { width: 49%; }
      div>img{
        width: 100%;
        height: 100%;
        transition: .5s;
        object-fit: cover;
      }
      div>img:hover{
        transform: scale(1.05);
      }
    }
  }

  .model-part{
    img.title-img{ width: 56%; margin: 0 20%; }
    .part-content{
      position: relative;
      .text{ line-height: 26px; opacity: .8; padding: 0 36px;}
      .video-wrap{
        position: relative;
        width: 110%;
        left: 50%;
        transform: translateX(-50%);
        top: -20px;
        background: url('~@/assets/experienceCenter/video-bg.png') no-repeat center bottom/100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 80px;
        padding-bottom: 80px;
        .video-box{
          position: relative;
          width: 62%;
          height: 23.5vw;
          max-height: 450px;
          max-width: 810px;
          video{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        // 播放按钮
        .play-btn{
          position: absolute;
          width: 100px;
          height: 100px;
          border: 5px solid #fff;
          border-radius: 50%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          justify-content: center;
          &::after{
            display: block;
            content: '';
            border-left: 30px solid #fff;
            border-right: 20px solid transparent;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            margin-left: 32%;
          }
        } 
        }

        .video-desc{
          width: 62%;
          text-align: center;
          margin-top: 40px;
        }
      }
      .code-wrap{
        width: 55%;
        margin: 0 auto;
        img{margin-right: 22px;}
        hr{
          width: 320px;
          height: 2px;
          background-color: transparent;
          background-image: linear-gradient(90deg, rgba(255, 255, 255, .5) 0%, rgba(255, 255, 255, 0) 100%);
          border: none;
          margin: 32px 0 23px;
        }
      }
    }
  }
</style>